// AptosWalletConnect.tsx
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useWallet, WalletName } from "@aptos-labs/wallet-adapter-react";
import logoapp from "../../../../assets/images/teleImage/iconSlime.png";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { FaBook } from "react-icons/fa";
type AptosWalletConnectType = {
	onClose: () => void;
};
export default function AptosWalletConnect({
	onClose,
}: AptosWalletConnectType) {
	const { wallets, connect, account } = useWallet();
	const [isLoading, setLoading] = useState(true);
	const [isLoaded, setLoaded] = useState(false);

	const onConnect = async (walletName: WalletName) => {
		try {
			connect(walletName);
			onClose();
		} catch (error) {
			console.error("Error connecting wallet:", error);
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (account?.address !== undefined) {
				onClose();
			} else {
				setLoading(false);
			}
		}, 3000);
		return () => clearTimeout(timer);
	}, [account]);

	const YourComponent = () => {
		return (
			<div className={styles.container}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						columnGap: "10px",
						fontFamily: "Poppins",
					}}
				>
					<img src={logoapp} alt="Logo" className={styles.logo} />
					<h1 className={styles.title}>Slime Revolution</h1>
				</div>
				<p className={styles.subtitle}>
					{`Slime Revolution is a blockchain-based social game that merges gaming excitement with cryptocurrency rewards`}
				</p>
				{account?.address === undefined && !isLoading ? (
					wallets?.map(
						(wallet) =>
							wallet.name === "Continue with Google" && (
								<button
									key={wallet.name}
									className={styles.connectMetamaskButton}
									onClick={() => {
										onConnect(wallet.name);
										onClose();
									}}
								>
									<img src={wallet.icon} alt="" />
									{wallet.name}
								</button>
							)
					)
				) : (
					<button className={styles.connectMetamaskButton}>
						<img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBjbGFzcz0iaF8yMCB3XzIwIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIzLjU0IDEyLjc2MTNDMjMuNTQgMTEuOTQ1OSAyMy40NjY4IDExLjE2MTggMjMuMzMwOSAxMC40MDkxSDEyLjVWMTQuODU3NUgxOC42ODkxQzE4LjQyMjUgMTYuMjk1IDE3LjYxMjMgMTcuNTEyOSAxNi4zOTQzIDE4LjMyODRWMjEuMjEzOEgyMC4xMTA5QzIyLjI4NTUgMTkuMjExOCAyMy41NCAxNi4yNjM2IDIzLjU0IDEyLjc2MTNaIiBmaWxsPSIjNDI4NUY0Ij48L3BhdGg+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi40OTk1IDIzLjk5OThDMTUuNjA0NSAyMy45OTk4IDE4LjIwNzcgMjIuOTcgMjAuMTEwNCAyMS4yMTM3TDE2LjM5MzggMTguMzI4MkMxNS4zNjQgMTkuMDE4MiAxNC4wNDY3IDE5LjQyNTkgMTIuNDk5NSAxOS40MjU5QzkuNTA0MjUgMTkuNDI1OSA2Ljk2OTAyIDE3LjQwMyA2LjA2NDcgMTQuNjg0OEgyLjIyMjY2VjE3LjY2NDRDNC4xMTQ5MyAyMS40MjI4IDguMDA0MDIgMjMuOTk5OCAxMi40OTk1IDIzLjk5OThaIiBmaWxsPSIjMzRBODUzIj48L3BhdGg+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjA2NTIzIDE0LjY4NTFDNS44MzUyMyAxMy45OTUxIDUuNzA0NTUgMTMuMjU4MSA1LjcwNDU1IDEyLjUwMDFDNS43MDQ1NSAxMS43NDIyIDUuODM1MjMgMTEuMDA1MSA2LjA2NTIzIDEwLjMxNTFWNy4zMzU1N0gyLjIyMzE4QzEuNDQ0MzIgOC44ODgwNyAxIDEwLjY0NDQgMSAxMi41MDAxQzEgMTQuMzU1OCAxLjQ0NDMyIDE2LjExMjIgMi4yMjMxOCAxNy42NjQ3TDYuMDY1MjMgMTQuNjg1MVoiIGZpbGw9IiNGQkJDMDUiPjwvcGF0aD48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEyLjQ5OTUgNS41NzM4NkMxNC4xODc5IDUuNTczODYgMTUuNzAzOCA2LjE1NDA5IDE2Ljg5NTYgNy4yOTM2NEwyMC4xOTQgMy45OTUyM0MxOC4yMDI0IDIuMTM5NTUgMTUuNTk5MiAxIDEyLjQ5OTUgMUM4LjAwNDAyIDEgNC4xMTQ5MyAzLjU3NzA1IDIuMjIyNjYgNy4zMzU0NUw2LjA2NDcgMTAuMzE1QzYuOTY5MDIgNy41OTY4MiA5LjUwNDI1IDUuNTczODYgMTIuNDk5NSA1LjU3Mzg2WiIgZmlsbD0iI0VBNDMzNSI+PC9wYXRoPjwvc3ZnPg==" />
						<div className={styles.loading}>
							Google Verify
							<span className={styles.dot}>.</span>
							<span className={styles.dot}>.</span>
							<span className={styles.dot}>.</span>
						</div>
					</button>
				)}

				<div className={styles.aboutStyle}>
					<a
						href="https://t.me/SlimeRoyale"
						target="_blank"
						style={{
							color: "black",
							fontFamily: "Poppins",
							fontSize: "12px",
							display: "flex",
							alignItems: "center",
							columnGap: "4px",
						}}
						rel="noopener noreferrer"
					>
						<FaTelegramPlane size={16} color="black" />
						{` Join Telegram`}
					</a>
					<a
						href="https://x.com/SlimeRoyale/"
						target="_blank"
						style={{
							color: "black",
							fontFamily: "Poppins",
							fontSize: "12px",
							display: "flex",
							alignItems: "center",
							columnGap: "4px",
						}}
						rel="noopener noreferrer"
					>
						<FaTwitter size={16} color="black" />
						{` Follow us on Twitter`}
					</a>
					<a
						style={{
							color: "black",
							fontFamily: "Poppins",
							fontSize: "12px",
							display: "flex",
							alignItems: "center",
							columnGap: "4px",
						}}
						href="https://test-dapp.slimeroyale.com/home"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaBook size={16} color="black" />
						{` About Slime Revolution`}
					</a>
				</div>
			</div>
		);
	};

	return (
		<div className={`${styles.loading__game}`}>
			<div className={styles.loadingContainer}>
				<YourComponent />
			</div>
		</div>
	);
}
