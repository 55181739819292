import React, { useEffect, useRef, useState } from "react";
import { RWebShare } from "react-web-share";
import styles from "./index.module.scss";

import iconCopy from "../../../../../assets/images/iconCopy.png";
import PirateIcon from "../../../../../assets/roll/modals/Pirate.png";
import accountImg from "../../../../../assets/images/teleImage/accountImg.png";
import iconUSDT from "../../../../../assets/roll/modals/USDT.png";
import emblem from "../../../../../assets/images/teleImage/emblem.png";
import emblem1 from "../../../../../assets/images/teleImage/emblem1.png";
import { InfoData, ResUrl } from "../../../../../lib/models/api";
import iconAccount from "../../../../../assets/images/teleImage/icon_account.png";
import iconProfile from "../../../../../assets/images/common/iconProfile.png";
import iconCopyNew from "../../../../../assets/images/teleImage/icon_copy.png";
import iconCoin from "../../../../../assets/images/teleImage/icon_coin.png";
import iconUSDTNew from "../../../../../assets/images/teleImage/USDT.png";
import baseWallet from "../../../../../assets/images/teleImage/baseWallet.png";
import iconETH from "../../../../../assets/images/teleImage/ETH.png";

import {
	renderBtnType,
	RenderTextFieldType,
} from "../../../../../lib/types/button";
import {
	apiGetMyAccount,
	requestUpdateProfile,
	startWithDraw,
	withdrawTimeInfo,
} from "../../../../../services/accountApiService";
import ChoosePlanFarming from "../../../../../commonsUi/modals/choosePlanFarming";
import ButtonClaim from "../../../../../commonsUi/buttons/buttonClaim";
import ModalRewardUSDT from "../../../../../commonsUi/modals/modalRewardUSDT";
import { writeClipboardText } from "../../../../../utils/function/text";
import AptosWalletConnect from "../../walletConnect";
import WalletConnect from "../../walletConnect";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import ModalWithDrawUSDT from "../../../../../commonsUi/modals/modalWithDrawUSDT";
import { stavaxAccount } from "../../../../../connectStavax";
import { useAccount, useDisconnect, useBalance } from "wagmi";
import ButtonRectangle from "../../../../../commonsUi/buttons/buttonRectangle";
import ModalConfirmTSPC from "../../../../../commonsUi/modals/ModalConfirmtSPC";
import ModalDeposit from "../../../../../commonsUi/modals/ModalDeposit";
import ReachUSDTModal from "../../../../../commonsUi/modals/reachUstd";
import ModalConnectWallet from "../../../../../commonsUi/modals/ConnectWalletModal";
export const formatAddress = (addr: any): string => {
	const start = addr.slice(0, 10);
	const end = addr.slice(-3);
	return `${start}...${end}`;
};
type AccountAppProps = {};

const AccountPage: React.FC<AccountAppProps> = ({}) => {
	const [showCopy, setShowCopy] = useState<boolean>(false);
	const [showCopy1, setShowCopy1] = useState<boolean>(false);
	const [resUrl, setResUrl] = useState<ResUrl>();
	const [hasMounted, setHasMounted] = useState<boolean>(false);
	const [infoData, setInfoData] = useState<InfoData | null>(null);
	const { disconnect: disconnectWagmi, disconnectAsync } = useDisconnect();
	const [isShowInvite, setShowInvite] = useState<boolean>(false);
	const [showCheckIn, setShowCheckIn] = useState<boolean>(false);
	const [showPopupUSDT, setShowPopupUSDT] = useState<boolean>(false);
	const [showPopupAsk, setShowPopupAsk] = useState<boolean>(false);
	const [isShowWithDraw, setShowWithDraw] = useState<boolean>(false);
	const [timeClickWithDraw, setTimeClickWithDraw] = useState<number>(0);
	const [isShowPopupTSPC, setIsShowPopupTSPC] = useState<boolean>(false);
	const [isShowPopupDeposit, setShowPopupDeposit] = useState<boolean>(false);
	const [ethBalence, setETHBalance] = useState<string | number | "">();
	const shareRef = useRef<HTMLDivElement | null>(null);
	// const { connect, connectors } = useConnect<Config, unknown>();
	const [isShowConnectWalelt, setShowConnectWallet] = useState(false);
	const [isShowReachUSDDT, setReachUSDT] = useState(false);
	const [ethToUSDT, setEthToUsdt] = useState(0);
	const [isShowConnectWalletStavax, setShowConnectWalletStavax] =
		useState(false);
	const accountWagmi = useAccount();
	const result = useBalance({
		address: accountWagmi!.address,
	});

	const RenderBtn = ({ isEnable, title, onClick }: renderBtnType) => {
		return (
			<div
				className={`${
					isEnable ? styles.enableBtnStyle : styles.disableBtnStyle
				} active-btn`}
				onClick={onClick}
			>
				{title}
			</div>
		);
	};
	const requestStartWithDraw = async () => {
		const resStartWithDraw = await startWithDraw();
	};
	const requestWithdrawTimeInfo = async () => {
		return await withdrawTimeInfo();
	};

	const handleCallApiPillageJourneyInfoAgain = async (): Promise<void> => {
		const responsePillageJourneyInfo: any = await apiGetMyAccount();
		if (responsePillageJourneyInfo?.success) {
			setInfoData(responsePillageJourneyInfo.data);
			console.log({ responsePillageJourneyInfo });
		} else {
		}
	};

	const handleShowPopupUSDT = async () => {
		await requestStartWithDraw();
		const resWithdrawTimeInfo: any = await requestWithdrawTimeInfo();
		if (resWithdrawTimeInfo) {
			if (new Date(resWithdrawTimeInfo.data.TimeWithDraw) <= new Date()) {
				setShowWithDraw(true);
				return;
			}

			setTimeClickWithDraw(
				new Date(resWithdrawTimeInfo.data.TimeWithDraw).getTime()
			);
		}
		setShowPopupUSDT(true);
	};

	const onClickButtonQuickUSDT = () => {
		setShowPopupUSDT(false);
		setShowInvite(true);
	};
	const handleClosePopupUSDT = () => {
		setShowPopupUSDT(false);
	};

	const ethBalance = useBalance({
		address: accountWagmi?.address,
	});

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCopy(false);
		}, 1000);
		return () => clearTimeout(timer);
	}, [showCopy]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCopy1(false);
		}, 1000);
		return () => clearTimeout(timer);
	}, [showCopy1]);

	useEffect(() => {
		if (!hasMounted) {
			handleCallApiPillageJourneyInfoAgain();
			setHasMounted(true);
		}
	}, [hasMounted]);

	useEffect(() => {
		const handleUpdateAccount = async (): Promise<any> => {
			if (accountWagmi?.address !== undefined) {
				await requestUpdateProfile(accountWagmi.address);
			}
		};
		handleUpdateAccount();
	}, [accountWagmi]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCheckIn(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [showCheckIn]);

	const RenderTextField = ({
		placeHolder,
		title,
		isButton = true,
		btnTile,
		isEnable,
		onClick,
		onClickText,
	}: RenderTextFieldType) => {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "self-start",
				}}
			>
				<p className={styles.titleStyle}>{title}</p>
				<div className={styles.inputStyle1}>
					<p
						onClick={onClickText}
						style={{
							width: "58vw",
							overflow: "hidden",
							textAlign: "start",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{placeHolder}
					</p>
					{isButton ? (
						<RenderBtn
							onClick={onClick}
							title={btnTile}
							isEnable={isEnable}
						></RenderBtn>
					) : (
						<>
							<img
								onClick={() => {
									setShowCopy(true);
									writeClipboardText(resUrl?.userId);
								}}
								src={iconCopy}
								alt="iconCopy"
								style={{
									marginRight: "10px",
									width: "30px",
									height: "30px",
									marginTop: "10px",
								}}
							/>
							{showCopy && (
								<div className={styles.copyStyle}>Copied</div>
							)}
						</>
					)}
				</div>
			</div>
		);
	};

	const RenderBody = () => {
		return (
			<>
				<div className={styles.cardTileStyle}>Wallet</div>
				<div className={styles.bodyHeader}>
					<div
						className={styles.wallet__connect}
						style={{
							borderBottom: "none",
						}}
					>
						<img
							src={baseWallet}
							alt="base wallet"
							width={171}
							height={35}
						/>
						<p
							className={styles.item__account__withdraw}
							onClick={async () => {
								if (accountWagmi?.address !== undefined) {
									disconnectWagmi();
									return;
								} else {
									// console.log("Asdf");
									// setShowConnectWallet(true);
									const session =
										await stavaxAccount.connect();
									console.log({ session });
									const botURL =
										stavaxAccount.getTgBotWebAppURL(
											session!
										).value;
									console.log({ botURL });
								}

								// const session = await stavaxAccount.connect();
								// console.log({ session });
								// const botURL = stavaxAccount.getTgBotWebAppURL(
								// 	session!
								// ).value;
								// console.log({ botURL });
							}}
						>
							{`${
								accountWagmi?.address !== undefined
									? "Disconnect"
									: "Connect"
							}`}
						</p>
					</div>
					<div className={styles.wallet__connect}>
						<div className={styles.wallet__left}>
							<p>
								Address:{" "}
								{formatAddress(
									accountWagmi?.address !== undefined
										? accountWagmi?.address
										: ""
								)}
							</p>
							<img
								src={iconCopyNew}
								width={25}
								alt="icon copy new"
								onClick={async () => {
									setShowCheckIn(true);
									writeClipboardText(accountWagmi?.address);
								}}
							/>
						</div>
					</div>
					<div className={styles.wallet__eth}>
						<img
							src={iconETH}
							alt="icon eth"
							width={27}
							height={27}
						/>
						<p>
							ETH Balance: {ethBalance?.data?.formatted || ""}{" "}
							{` (${ethToUSDT.toFixed(2)}$)`}
						</p>
						<p></p>
					</div>

					<div>
						<h3 className={styles.title__wallet}>
							Top up your account
						</h3>
						<p className={styles.title__content}>
							Fund your wallet with ETH on Base to make purchases.
						</p>
						<div className={styles.group__button}>
							{accountWagmi?.address !== undefined && (
								<ButtonRectangle
									text="Deposit ETH On Base"
									classCssText="text__on__base"
									classCssButton="button__on__base"
									onClickButton={() =>
										setShowPopupDeposit(true)
									}
								/>
							)}
							{/* <ButtonRectangle
								text="Bridge ETH From Mainnet"
								classCssText="text__main__net"
								classCssButton="button__main__net"
								onClickButton={() => {
									window.open(
										`https://relay.link/bridge/base?fromChainId=1&toChainId=8453&amount=0.01&token=ETH&message=Frenb+Wallet+Top+Up+-+`
									);
								}}
							/> */}
						</div>
					</div>
				</div>
			</>
		);
	};
	useEffect(() => {
		setResUrl({
			userId: localStorage.getItem("_userId"),
		});
	}, []);
	const buyItem = async () => {
		try {
			const bnbUsdtPriceResponse = await fetch(
				"https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT"
			);
			const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
			if (ethBalance?.data?.formatted !== undefined) {
				const value =
					Number(ethBalance?.data?.formatted) *
					bnbUsdtPriceData.price;
				setEthToUsdt(value);
				console.log({ value });
			}
		} catch (error) {
			console.error("Error fetching BNB/USDT price:", error);
		}
	};
	useEffect(() => {
		buyItem();
	}, [accountWagmi]);
	const handleShare = async () => {
		const shareLink = `https://t.me/Slime_Revolution_bot?start=${resUrl?.userId}`;
		if (navigator.share) {
			try {
				await navigator.share({
					title: "Slime Revolution",
					text: "Play Slime Revolution with me!",
					url: shareLink,
				});
			} catch (error) {}
		} else {
		}
	};
	const RenderHeader = () => {
		return (
			<>
				<div
					style={{
						width: "200px",
						display: "flex",
						marginLeft: "106px",
						alignItems: "center",
						justifyContent: "center",
						marginBottom: "30px",
					}}
				>
					<img
						src={accountImg}
						alt="accountImg"
						style={{ width: "66vw", height: "44px" }}
					/>
					{/* <Image
                        src={buttonCancel}
                        width={30}
                        height={30}
                        alt="button cancel"
                        onClick={onClick}
                    /> */}
				</div>
				<div className={styles.cardTileStyle}> Game Account</div>
				<div className={styles.bodyHeader}>
					<div className={styles.item__account}>
						<div className={styles.item__account__left}>
							<img
								src={iconProfile}
								className={styles.item__account__icon}
								alt="icon account"
							/>
							<p className={styles.item__account__text}>
								User name: {`${infoData?.actort.Username}`}
							</p>
						</div>
					</div>
					<div className={styles.item__account}>
						<div className={styles.item__account__left}>
							<img
								src={iconAccount}
								className={styles.item__account__icon}
								alt="icon account"
							/>
							<p className={styles.item__account__text}>
								UID: {`${infoData?.actort.UserId}`}
							</p>
						</div>
						<img
							src={iconCopyNew}
							alt="icon copy"
							className={styles.item__account__icon__copy}
							onClick={async () => {
								setShowCheckIn(true);
								writeClipboardText(
									infoData?.actort.UserId.toString()
								);
							}}
						/>
					</div>
					<div className={styles.item__account}>
						<div className={styles.item__account__left}>
							<img
								src={iconCoin}
								className={styles.item__account__icon}
								alt="icon crystal"
							/>
							<p className={styles.item__account__text}>
								{(infoData?.SpcInChest !== undefined &&
									Math.floor(infoData?.SpcInChest)) ||
									0}{" "}
								tSPC
							</p>
						</div>
						<span
							id="button"
							className={styles.item__account__withdraw}
							onClick={() => setIsShowPopupTSPC(true)}
						>
							Withdraw
						</span>
					</div>
					<div className={styles.item__account}>
						<div className={styles.item__account__left}>
							<img
								src={iconUSDTNew}
								className={styles.item__account__icon}
								alt="icon USDT"
							/>
							<p className={styles.item__account__text}>
								{`${
									infoData?.Usdt != null
										? Number(infoData?.Usdt).toFixed(2)
										: 0
								}`}{" "}
								$
							</p>
						</div>
						<span
							id="button"
							className={styles.item__account__withdraw}
							onClick={
								infoData?.Usdt != null && infoData?.Usdt >= 5
									? handleShowPopupUSDT
									: () => {
											setReachUSDT(true);
									  }
							}
						>
							Withdraw
						</span>
					</div>
				</div>
			</>
		);
	};
	const RenderBottom = () => {
		return (
			<>
				<div className={styles.cardTileStyle}> Your Referral Code</div>
				<div className={styles.bodyHeader}>
					<RWebShare
						data={{
							title: "Slime Revolution",
							text: "Play Slime Revolution with me!",
							url: `https://t.me/Slime_Revolution_bot?start=${resUrl?.userId}`,
						}}
						onClick={() => console.log("shared successfully!")}
					>
						<RenderTextField
							placeHolder={`https://t.me/Slime_Revolution_bot?start=${resUrl?.userId}`}
							btnTile="Share"
							isEnable={true}
							isButton={true}
						/>
					</RWebShare>
					{/* <Image
                        src={emblem1}
                        alt="emblem"
                        className={styles.imgHeaderStyle}
                        height={56}
                    /> */}
				</div>
			</>
		);
	};

	return (
		<div className={styles.main}>
			<RenderHeader />
			<RenderBody />
			{/* <RenderBottom /> */}
			{accountWagmi.address === undefined && (
				<ModalConnectWallet onClosePopup={() => {}} />
			)}
			{showCheckIn && <div className={styles.notice1}>{`copied`}</div>}
			{isShowReachUSDDT && (
				<ReachUSDTModal
					onClose={() => {
						setReachUSDT(false);
					}}
				/>
			)}
			{isShowInvite && (
				<ChoosePlanFarming
					iconPopup={PirateIcon}
					onClose={() => setShowInvite(false)}
				>
					<div className={styles.wrapper}>
						<p className={styles.confirmPurchaseTitle}>
							Ask 5 friends for support to verify quickly
						</p>
						<div>
							<div className={styles.copyMainStyle}>
								<img
									onClick={() => {
										setShowCopy1(true);
										writeClipboardText(
											`https://t.me/Slime_Revolution_bot?start=${resUrl?.userId} \n\nPlay-to-Airdrop! Start your journey to earn tSPC. The more tSPC you get, the bigger your Airdrop. Earn now!`
										);
									}}
									src={iconCopy}
									alt="iconCopy"
									style={{
										width: "30px",
										height: "30px",
										marginTop: "10px",
										position: "absolute",
										right: "4px",
									}}
								/>
								<div className={styles.classRefer}>
									<p className={styles.referLinkStyle}>
										Your referral link
									</p>
									<p
										className={styles.desReferLink}
									>{`https://t.me/Slime_Revolution_bot?start=${resUrl?.userId}`}</p>
								</div>
								{showCopy1 && (
									<div className={styles.copyStyle1}>
										Copied
									</div>
								)}
							</div>
						</div>
						<div className={styles.button_popup_add_now}>
							<div>
								<ButtonClaim
									backGround={"bgc_button_up_level"}
									onClickButton={() => {
										setShowInvite(false);
										if (shareRef.current) {
											shareRef.current.click();
										}
									}}
								>
									<p className={styles.button_ask_now}>
										Ask now
									</p>
								</ButtonClaim>
								<p
									className={styles.text_invite_count}
								>{`*Need at least 5 friends to verify (${
									infoData?.invitedCount || 0
								}/5)`}</p>
							</div>
						</div>
					</div>
				</ChoosePlanFarming>
			)}
			<RWebShare
				data={{
					title: "Slime Revolution",
					text: "Play Slime Revolution with me!",
					url: `https://t.me/Slime_Revolution_bot?start=${resUrl?.userId}`,
				}}
				onClick={() => console.log("shared successfully!")}
			>
				<p ref={shareRef}></p>
			</RWebShare>
			{showPopupUSDT && (
				<ModalRewardUSDT
					onClickButtonQuickUSDT={onClickButtonQuickUSDT}
					onClosePopup={handleClosePopupUSDT}
					timeCountDown={timeClickWithDraw}
					onClickWithDraw={() => {
						setShowPopupUSDT(false);
						setShowWithDraw(true);
					}}
				/>
			)}
			{isShowWithDraw && (
				<ModalWithDrawUSDT
					onClosePopup={() => {
						setShowWithDraw(false);
						handleCallApiPillageJourneyInfoAgain();
					}}
					title="You can claim $5 USDT!"
					des="Congratulation! Your account is verified and eligible for withdrawal."
				/>
			)}
			{isShowConnectWalelt && (
				<WalletConnect onClose={() => setShowConnectWallet(false)} />
			)}
			{isShowPopupTSPC && (
				<ModalConfirmTSPC onClose={() => setIsShowPopupTSPC(false)} />
			)}
			{isShowPopupDeposit && (
				<ModalDeposit
					addressWallet={formatAddress(
						accountWagmi?.address !== undefined
							? accountWagmi?.address
							: ""
					)}
					onClose={() => setShowPopupDeposit(false)}
				/>
			)}
		</div>
	);
};

export default AccountPage;
