const ENDPOINTS = {
	API_CREATE_ACCOUNT: "/api/user/createAccount",
	API_QUEST_TELEGRAM: "/api/quest-telegram/user",
	API_CHECK_IN_CONTRACT: "/api/user/check-in/contract",
	API_QUEST_TELEGRAM_CONFIRM: "/api/quest-telegram/confirm/",
	API_INFO_CHECK_IN_SMART_WALLET: "/api/v1/user/info-check-in-smart-wallet",
	API_USER_INFO: "/api/user/info",
	API_UPDATE_SMART_WALLET: "/api/v1/user/update-smart-wallet",
	API_WITHDRAW_START_CLICK: "/api/v1/withdraw/start-click",
	API_RANKING: "/api/v1/island/ranking",
	API_WITHDRAW_INFO: "/api/v1/withdraw/info",
	API_WITHDRAW_USDT: "/api/v1/withdraw/usdt",
	API_WITHDRAW_UPDATE_STATUS: "/api/v1/withdraw/update-status-with-draw",
	API_WITHDRAW_UPDATE_PAYMENT: "/api/v1/shop/purchase-v2",
	API_SHOP_PACKAGE: "/api/v1/shop",
	API_COLLECTIONS_LIST: "/api/v1/collections/list",
	API_CARDS_WAIT_LISTS_MINT: "/api/v1/cards/wait-lists-mint",
	API_COLLECTIONS_LIST_CARDS: "/api/v1/collections/list-cards/",
	API_USER_REWARD_7DAY: "/api/v1/user/reward-7day",
	API_USER_REWARD_7DAY_POST: "/api/v1/user/reward-7day",
	API_SHOP_PACKAGE_USER: "/api/info/shop/package/",
	API_USER_BY_ID: "/api/user/",
	USER_INFO: "/api/user/info-tele",
	UPDATE_SMART_WALLET: "/api/v1/user/update-smart-wallet",
};

export { ENDPOINTS };
