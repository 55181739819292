import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import walletIcon from "../../../assets/walletIcon.png";
import LoadingCommon from "../../CommonUI/LoadingApp";
import MetamaskImg from "../../../assets/images/common/MetamaskImg.png";
import StavaxImg from "../../../assets/images/common/StavaxImg.png";
import TonImg from "../../../assets/images/common/TonImg.png";
import GameButton from "../../commonButton/gameButton/GameButton";
import {
	Locales,
	useTonConnectUI,
	TonConnectButton,
} from "@tonconnect/ui-react";
import { useTonWallet } from "@tonconnect/ui-react";
import { useTonAddress } from "@tonconnect/ui-react";
import { decodeJwt, encodeJwt, tonToNanoton } from "../../../utils/extension";
import { paymentRequest } from "../../../services/accountApiService";
type ChooseWalletType = {
	onClose: () => void;
	onClickMetatask?: () => void;
	onClickStavax?: () => void;
	isLoading?: boolean;
	customStyle?: React.CSSProperties;
	showIcon?: boolean;
	showClose?: boolean;
	dataPros: any;
};
export default function ChoseWallet({
	onClose,
	onClickMetatask,
	onClickStavax,
	isLoading = false,
	customStyle,
	showIcon = true,
	showClose = true,
	dataPros,
}: ChooseWalletType) {
	const userFriendlyAddress = useTonAddress();
	const [tonConnectUI, setOptions] = useTonConnectUI();
	const [paymentSuccess, setPaymentSuccess] = useState(false);

	const requestPayment = async (PackageId: number, hash: string) => {
		try {
			const value = {
				PackageId: PackageId,
				transactionHash: hash,
			};
			const payLoadData = await encodeJwt(value);
			await paymentRequest({ value: payLoadData });
		} catch (e) {
			console.log({ e });
		}
	};

	useEffect(() => {
		if (userFriendlyAddress !== "") {
			sendTransaction();
		}
	}, [userFriendlyAddress]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setPaymentSuccess(false);
		}, 5000);
		return () => clearTimeout(timer);
	}, [paymentSuccess]);
	const delay = (ms: number) =>
		new Promise((resolve) => setTimeout(resolve, ms));
	const sendTransaction = async () => {
		const bnbUsdtPriceResponse = await fetch(
			"https://api.binance.com/api/v3/ticker/price?symbol=TONUSDT"
		);
		const dataDecode: any = await decodeJwt(dataPros!);
		const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
		try {
			const hash = await tonConnectUI.sendTransaction({
				validUntil: Math.floor(Date.now() / 1000) + 60,
				messages: [
					{
						address:
							"UQBkkD_ESQA64FNXlCQve2673XcCgx7gm4h9rBfewBtEvKcj",
						amount: `${tonToNanoton(
							dataDecode!.price! / bnbUsdtPriceData.price
						).toFixed(0)}`,
					},
				],
			});
			await requestPayment(dataDecode!.id, hash.boc);
			setPaymentSuccess(true);
			tonConnectUI.disconnect();
			await delay(7000);
			onClose();
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<div className={style.main}>
			<div className={`${style.popup}`}>
				<LoadingCommon
					visible={isLoading}
					width={80}
					height={80}
					isLeft10={true}
				/>
				{showIcon && (
					<img
						src={walletIcon}
						width={171}
						alt="slime"
						className={style.popup__icon}
						style={customStyle}
					/>
				)}
				{showClose && (
					<img
						src={buttonCancel}
						width={36}
						height={38}
						alt="button cancel"
						className={style.button__close}
						onClick={onClose}
					/>
				)}
				{paymentSuccess && (
					<div
						className={style.checkedInStyle}
					>{`You have successfully purchased. Please check your mailbox to claim the item.`}</div>
				)}
				<div className={`${style.buttonContainer}`}>
					<p
						className={`${style.button} ${style.metamask} active-btn`}
						onClick={onClickMetatask}
					>
						<img src={MetamaskImg} alt="Metamask" /> Metamask
					</p>
					<p
						className={`${style.button} ${style.stavax} active-btn`}
						onClick={onClickStavax}
					>
						<img src={StavaxImg} alt="Stavax" /> Stavax
					</p>
					<p
						className={`${style.button} ${style.ton} active-btn`}
						onClick={async () => {
							await tonConnectUI.openModal();
						}}
					>
						<img src={TonImg} alt="TON" /> TON{" "}
						{/* <span>(Coming soon)</span> */}
					</p>
				</div>
			</div>
			<div className={`${style.modalOverlay}`}></div>
		</div>
	);
}
